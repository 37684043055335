export enum NanoFeature {
  PEEK = 2, // peek file from drive one-by-one - nano 0.5.4
  THUMBNAIL = 3, // thumbnail batch query for drive folder - nano 0.9.2
  TRUST = 4, // user trust verification feature set, img preview - nano 1.1
  AUTO_NAME = 5, // auto-name, auto-path feature, seek, peek img stale - nano 1.2
  BACKUP = 7, // smartphone backup
  // The following are Drive API extensions (they are bitwise added to the advertised API).
  DOCUMENT = 16,
}

export let EditorSupportedFileExtensions: Array<string> = [
  'txt',
  'odt',
  'ods',
  'odp',
  'doc',
  'docx',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
];

export let NANO_VERSION_FOR_FEATURE_REQUIRE_EXPLICIT_PEER_TRUST = '1.1.0';
export let NANO_VERSION_FOR_NANO_MANAGER_ADMIN_SESSION = '1.3.0';
export let NANO_VERSION_FOR_LOG_READ = '1.6.0';
export let NANO_VERSION_FOR_BACKUP = '1.7.0';

export function isNanoFeatureSupported(nanoDriveAPIVersion: number, feature: NanoFeature): boolean {
  if (feature < NanoFeature.DOCUMENT) {
    nanoDriveAPIVersion &= 15; // Remove extension bits for checking base drive version.
  }
  return nanoDriveAPIVersion >= feature;
}

export function nanoVersionCompare(v1, v2) {
  const version1 = v1.split('.').map(Number);
  const version2 = v2.split('.').map(Number);

  const length = Math.max(version1.length, version2.length);

  for (let i = 0; i < length; i++) {
    const num1 = i < version1.length ? version1[i] : 0;
    const num2 = i < version2.length ? version2[i] : 0;

    if (num1 > num2) return 1;
    if (num2 > num1) return -1;
  }

  return 0;
}
