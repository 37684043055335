<h2 mat-dialog-title>
  <span translate>Add Backup</span>
</h2>
<form [formGroup]="form">
  <mat-dialog-content class="mat-typography">
    <div id="dialog-content">
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label><span translate>Room ID</span></mat-label>
          <input matInput required name="room_id" formControlName="room_id" />
          <mat-error *ngIf="form.get('room_id').errors?.['required']"><span translate>Room ID can not be empty.</span></mat-error>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div id="room-chooser">
        <div class="room" [ngClass]="{ selected: r.id === form.get('room_id').value }" (click)="form.get('room_id').setValue(r.id)" *ngFor="let r of allRooms">
          <app-avatar [blob]="r.data?.avatar"></app-avatar>
          <div class="res-node-name">
            <span>{{ r.data ? r.data.name : r.id }}</span>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close><span translate>Cancel</span></button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="addBackup()"><span translate>OK</span></button>
  </mat-dialog-actions>
</form>
