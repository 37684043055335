import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subscription, merge } from 'rxjs';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { DeviceFile } from 'src/app/shared/services/blobsaver-adapter';
import { DialogService } from 'src/app/shared/services/dialog.service';
import {
  CollectionManagerType,
  DownloadManagerService,
  FileManagerType,
  backupProgressObserver,
  collectionRemovedObserver,
  downloadFinishedObserver,
  downloadPausedObserver,
  downloadResumedObserver,
  downloadStartedObserver,
  fileRemovedObserver,
  BackupStatus,
  backupStatusChanged,
  LastBackupData,
  backupDeviceFileProgressObserver,
} from 'src/app/shared/services/download-manager-service';
import { sideMenuActiveObserver } from 'src/app/shared/shared-observers';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NativeAppService } from 'src/app/shared/services/native-app.service';
import { ServerRestApiService } from 'src/app/shared/services/server-rest-api.service';

@Component({
  selector: 'app-download-manager',
  templateUrl: './download-manager.component.html',
  styleUrls: ['./download-manager.component.scss'],
})
export class DownloadManagerComponent implements OnInit, OnDestroy {
  fileList: FileManagerType[] = [];
  collectionList: CollectionManagerType[] = [];

  BackupStatus = BackupStatus;

  backupFileList: DeviceFile[] = [];
  backupErrorFileList: DeviceFile[] = [];
  backupDriveName: string = '';
  backupProgress: number = 0;
  backupCurrentFile: DeviceFile;
  partialProgress: number = 0;
  showPartialProgress: boolean = false;
  currentBackupStatus: BackupStatus = BackupStatus.DORMANT;
  lastBackupData: LastBackupData;
  isConnectionError: boolean = false;

  public canResumeDownload: boolean = true;

  private downloadObserversSub: Subscription;

  constructor(
    protected downloadManagerService: DownloadManagerService,
    private roomService: RoomService,
    private dialogService: DialogService,
    private nativeAppService: NativeAppService,
    private serverRestApiService: ServerRestApiService,
    private location: Location,
    private bottomSheetRef: MatBottomSheetRef<DownloadManagerComponent>
  ) {
    this.fileList = this.downloadManagerService.getFileList();
    this.collectionList = this.downloadManagerService.getCollectionList();
    this.lastBackupData = this.downloadManagerService.getLastBackupData();
  }
  ngOnDestroy(): void {
    this.downloadObserversSub.unsubscribe();
    this.serverRestApiService.unsubscribeOnWSDisconnected(this.onWSDisconnect);
    this.serverRestApiService.unsubscribeOnWSConnected(this.onWSConnect);
  }

  ngOnInit(): void {
    sideMenuActiveObserver.next(false);

    merge(collectionRemovedObserver, fileRemovedObserver).subscribe(() => {
      if (this.fileList.length === 0 && this.collectionList.length === 0)
        this.bottomSheetRef.dismiss();
    });

    this.canResumeDownload = this.downloadManagerService.canCreateDownloadTask();

    this.downloadObserversSub = merge(
      downloadStartedObserver,
      downloadFinishedObserver,
      downloadPausedObserver,
      downloadResumedObserver
    ).subscribe(() => {
      this.canResumeDownload = this.downloadManagerService.canCreateDownloadTask();
    });

    this.backupFileList = this.downloadManagerService.deviceFiles;

    this.serverRestApiService.subscribeOnWSDisconnected(this.onWSDisconnect);
    this.serverRestApiService.subscribeOnWSConnected(this.onWSConnect);

    if (this.nativeAppService.isOnApp()) this.initBackupEvents();
  }

  private initBackupEvents(): void {
    this.currentBackupStatus = this.downloadManagerService.getBackupStatus();
    this.backupCurrentFile = this.downloadManagerService.getLastBackupData().currentFile;
    this.backupProgress = this.downloadManagerService.getLastBackupData().currentProgress;

    backupStatusChanged.subscribe((newStatus) => {
      console.log('Backup status chage', newStatus);
      this.currentBackupStatus = newStatus;

      if (newStatus === BackupStatus.STARTED) this.backupProgress = 0;
      else if (newStatus === BackupStatus.DONE) {
        console.log('Backup done, errors: ', this.downloadManagerService.erroredDeviceFiles);

        this.backupErrorFileList = this.downloadManagerService.erroredDeviceFiles;
      }
    });

    backupProgressObserver.subscribe((progressObj) => {
      // console.log('Backup progress', progressObj);
      this.backupProgress = progressObj.index;
      this.backupCurrentFile = progressObj.deviceFile;
      this.partialProgress = 0;
      this.showPartialProgress = false;
    });

    backupDeviceFileProgressObserver.subscribe((prog) => {
      this.partialProgress = prog;
      this.showPartialProgress = true;
    });
  }

  onWSDisconnect = () => {
    this.isConnectionError = true;
  };

  onWSConnect = () => {
    this.isConnectionError = false;
  };

  isBackupStatus(checkStatus: BackupStatus): boolean {
    return this.currentBackupStatus === checkStatus;
  }

  public removeReady(): void {
    this.downloadManagerService.removeFinishedFromFileList();
  }

  async beginBackup(): Promise<void> {
    this.roomService.getNanoSession(this.lastBackupData.resourceId).then((nanoSession) => {
      this.downloadManagerService.createBackup(this.lastBackupData.resourceId, nanoSession.version);
    });
  }

  closeBackup(): void {
    backupStatusChanged.next(BackupStatus.DORMANT);
  }

  pauseBackup(): void {
    this.downloadManagerService.pauseBackupUpload();
  }

  resumeBackup(): void {
    this.downloadManagerService.resumeBackupUpload();
  }

  cancelBackup(): void {
    this.dialogService
      .openConfirmDialog(
        marker('Cancel backup'),
        marker('The backup is still running, are you sure you want to cancel?')
      )
      .subscribe((res) => {
        if (res) {
          this.downloadManagerService.cancelBackupUpload();
        }
      });
  }

  retryBackup(): void {
    this.roomService.getNanoSession(this.lastBackupData.resourceId).then((nanoSession) => {
      this.downloadManagerService.createBackup(
        this.lastBackupData.resourceId,
        nanoSession.version,
        this.backupErrorFileList
      );

      this.backupFileList = this.backupErrorFileList;
    });
  }
}
