export enum SheetAction {
  DOWNLOAD,
  DELETE,
  RENAME,
  GETLINK,
  GETPUBLICLINK,
  UPLOAD,
  NEWFOLDER,
  TILEVIEW,
  LISTVIEW,
  MOVE,
  PROPERTIES,
  FOLDERDOWNLOAD,
  DRIVEDOWNLOAD,
  BACKUPPHONE,
}

export enum ChatLayoutSheetAction {
  REACT,
  REPLY,
  EDIT,
  COPY,
  DELETE,
  COPY_FILE_URL,
}
