import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FileOpener } from '@capacitor-community/file-opener';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TextZoom } from '@capacitor/text-zoom';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../environments/environment';
import { FileSystemWritableFileStreamCommonInterface } from '../nano/nano-requests';
import { BlobService } from './blob.service';
import { BlobSaverAdapter, DeviceFile } from './blobsaver-adapter';
import { RouterHandler } from './router-handler.service';

export type NotificationParam = {
  id?: number;
  title?: string;
  text?: string;
  data?: Object;
  group?: string;
};

export enum NotificationCommands {
  NAVIGATE,
}

export interface ProgressNotificationParam extends NotificationParam {
  progressBar: { value?: number; enabled: boolean };
}

// @todo setup "app" plugin

@Injectable({
  providedIn: 'root',
})
export class NativeAppService {
  private blobSaverAdapter = new BlobSaverAdapter();

  constructor(
    private blobService: BlobService,
    private router: Router,
    private zone: NgZone,
    private routerHandler: RouterHandler,
    private cookieSerivce: CookieService,
    @Inject(DOCUMENT) private document
  ) {
    console.log('blobsaver', this.blobSaverAdapter);
    document['blobSaver'] = this.blobSaverAdapter;
    // Hide the splash (you should do this on app launch)
    SplashScreen.hide();

    // notification for messages
    this.document.addEventListener(
      'pause',
      () => {
        this._isAppInForeground = false;
      },
      false
    );

    this.document.addEventListener(
      'resume',
      () => {
        this._isAppInForeground = true;
        if (this.isOnApp()) {
          TextZoom.set({ value: 1 });
        }
      },
      false
    );

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      console.log('deeplink navigation', event);
      this.zone.run(() => {
        const slug = event.url.split(environment.site_base).pop();

        const [base, fragment] = slug.split('#');
        if (slug) {
          this.routerHandler.navigate([base], {
            fragment,
          });
        }
      });
    });

    if (this.isOnApp()) {
      TextZoom.set({ value: 1 });
    }
  }

  public getDeviceFiles(): Promise<Array<DeviceFile>> {
    if (this.isOnAndroid()) return this.blobSaverAdapter.getFiles();
    if (this.isOnIOS()) return this.blobSaverAdapter.initIOSPhotosLibraryForFetching();
    console.warn('this is not a supported platform for getDeviceFiles, return empty list for now');
    return Promise.resolve([]);
  }

  private savedChunks = {};
  public async getFileFetcherForIOS(fileAssetIndex: number, chunkSize: number) {
    let streamEnded = false;
    let data = new Blob();
    var maximumLoop = 10;

    //console.log("BACKUPDEBUG check chunk size", data.size, chunkSize)
    while (data.size < chunkSize && !streamEnded){
      let cachedBlob: ArrayBuffer = this.savedChunks[fileAssetIndex];
      maximumLoop--;
      if (maximumLoop == 0) throw "This is not bueno"

      //console.log("BACKUPDEBUG do we have blob?", cachedBlob, cachedBlob?.byteLength)
      if (cachedBlob && cachedBlob.byteLength > 0){
        var reqPartSize = chunkSize - data.size;

        if (cachedBlob.byteLength <= reqPartSize){
          //console.log("BACKUPDEBUG get all blob", cachedBlob.byteLength)
          data = new Blob([data, cachedBlob]);
          delete this.savedChunks[fileAssetIndex];
        }
        else{
          //console.log("BACKUPDEBUG get blob slice", reqPartSize)
          data = new Blob([data, cachedBlob.slice(0, reqPartSize)]);
          this.savedChunks[fileAssetIndex] = cachedBlob.slice(reqPartSize);
          //console.log("BACKUPDEBUG blob now", data.size, "stored blob", this.savedChunks[fileAssetIndex].byteLength)

        }

      }
      else{
        //console.log("BACKUPDEBUG get from server")
        let fetchData = await this.blobSaverAdapter.fetchChunkForIOSAsset(fileAssetIndex)
        //console.log("BACKUPDEBUG got from server", fetchData)
        this.savedChunks[fileAssetIndex] = fetchData.chunk
        streamEnded = fetchData.done
      }
    }
    
    let buffer = await data.arrayBuffer()
    return { chunk: buffer, done: streamEnded };
  }

  public getInfo() {
    return App.getInfo();
  }

  public getCookie(key: string) {
    /*return CapacitorCookies.getCookies({
      url: environment.site_base
    }).then((cookies)=>{
      return cookies[key];
    })*/

    return this.blobSaverAdapter.getCookie({
      url: environment.cookie_url,
      key,
    });
  }

  public setCookie(key: string, value: string, expires?: Date) {
    /*return CapacitorCookies.setCookie({
      url: environment.site_base,
      key,
      value,
      expires
    })*/

    return this.blobSaverAdapter.setCookie({
      url: environment.cookie_url,
      key,
      value,
      expires,
    });
  }

  public removeCookie(key: string) {
    /*return CapacitorCookies.deleteCookie({
      url: environment.site_base,
      key
    })*/

    return this.blobSaverAdapter.removeCookie({
      url: environment.cookie_url,
      key,
    });
  }

  private _isAppInForeground: boolean = true;
  public isAppInForeground() {
    return this._isAppInForeground;
  }

  public isOnApp() {
    return Capacitor.getPlatform() !== 'web';
  }

  public getAppState() {
    return App.getState();
  }

  public isOnAndroid(): boolean {
    return Capacitor.getPlatform() == 'android';
  }

  public isOnIOS(): boolean {
    return Capacitor.getPlatform() == 'ios';
  }

  public getFileWriter(fileName: string): Promise<FileSystemWritableFileStreamCommonInterface> {
    return this.blobSaverAdapter.getFileWriter(fileName, '({x}) {base}{ext}', (payload) => {
      return new Promise((resolve, reject) => {
        try {
          resolve(this.blobService.new([payload])); // { type: "application/octet-stream" }
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  public registerRunningProcess(processId) {}
  public removeRunningProcess(processId) {}

  public openFile(path: string) {
    FileOpener.open({
      filePath: path,
    })
      .then(() => {
        console.log('opened');
      })
      .catch((err) => {
        console.log('err', err);
      });
  }
  public openLinkInBrowser(url: string) {
    Browser.open({ url });
  }

  public clearNotification(notificationId): void {
    return;
  }

  public playNotificationSound() {}

  public callProgressNotification(noti: any) {}
  public callProgressErrorNotification(noti: any) {}
  public copyClipboard(text: string): Promise<void> {
    return Clipboard.write({
      string: text,
    });
  }

  public useCamera() {
    return Camera.checkPermissions()
      .then((perm) => {
        if (perm.camera == 'granted') {
          return perm;
        } else {
          return Camera.requestPermissions();
        }
      })
      .then((perm) => {
        if (perm.camera == 'granted') {
          return Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,
          }).then((result) => {
            return result.webPath;
          });
        } else {
          throw 'Permission Error';
        }
      });
  }

  public setDarkStatusbar() {
    // Display content under transparent status bar (Android only)
    StatusBar.setOverlaysWebView({ overlay: false });
    StatusBar.setStyle({ style: Style.Dark });
  }

  public setLightStatusbar() {
    StatusBar.setOverlaysWebView({ overlay: false });
    StatusBar.setStyle({ style: Style.Light });
  }
}
