import { Component, Inject, Injector, INJECTOR, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoomWithData } from 'src/app/shared/server-services/query-records/room-records';
import { RoomService } from 'src/app/shared/server-services/room.service';
import { NanoDialogBaseComponent } from '../../nano-base-dialog';

@Component({
  selector: 'app-admin-add-backup-dialog',
  templateUrl: './admin-add-backup-dialog.component.html',
  styleUrl: './admin-add-backup-dialog.component.scss',
})
export class AdminAddBackupDialogComponent
  extends NanoDialogBaseComponent<AdminAddBackupDialogComponent>
  implements OnInit
{
  form = new FormGroup({
    room_id: new FormControl('', { validators: [Validators.required] }),
  });

  public allRooms: RoomWithData[] = [];

  constructor(
    roomService: RoomService,
    @Inject(INJECTOR) injector: Injector
    //@Inject(MAT_DIALOG_DATA) public data
  ) {
    super(injector);
    roomService.getAllRoom().then((rooms) => {
      this.allRooms = <RoomWithData[]>rooms;
    });
  }

  ngOnInit(): void {}

  public addBackup() {
    this.dialogRef.close(this.form.value);
  }
}
