declare const Capacitor;
import type { BlobSaverPlugin } from '../../../../blobsaver/src/definitions';

export type DeviceFile = {
  name: string;
  ext: string;
  path: string;
  mimeType: string;
  size: number;
  mTime: number;
  mtFrac: number;
  fileIndexInIOSAssetList?: number; // for ios, the index of the file in the list
  backupName?: string; // alias when it's original name is already used
};

export type IOSLoadPhotosType = { f_name: string; m_date: number; size: number, mt_frac: number }

export class BlobSaverAdapter {
  private blobSaverAuthToken?: string;

  public getPort = () => {
    return Capacitor.Plugins.BlobSaver.getPort().then((res) => {
      return res.port;
    });
  };

  public getAuthToken = () => {
    if (this.blobSaverAuthToken) return Promise.resolve(this.blobSaverAuthToken);

    return Capacitor.Plugins.BlobSaver.getAuthToken().then((res) => {
      this.blobSaverAuthToken = res.token;
      return res.token;
    });
  };

  public getCookie = (options: { url: string; key: string }) => {
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).getCookie(options).then((res) => {
      return res[options.key];
    });
  };

  setCookie(options: { url: string; key: string; value: string; expires: Date }) {
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).setCookie(options);
  }

  removeCookie(options: { url: string; key: string }) {
    return (<BlobSaverPlugin>Capacitor.Plugins.BlobSaver).removeCookie(options);
  }

  /**
   *
   * @param {id || name, close?, truncate?, overwrite: String("{base}, {ext}, {x}")} options -
   * @param {Blob} blob - File data
   * @returns {id?, name?} resp
   */
  uploadFile = (
    options: {
      id?: any;
      name?: string;
      close?: boolean;
      truncate?: boolean;
      overwrite?: string;
    },
    blob: Blob = new Blob([])
  ) => {
    return this.getPort().then((port) => {
      return this.getAuthToken().then((auth) => {
        const queryParams = new URLSearchParams(options as any).toString();
        return fetch('http://localhost:' + port + '?' + queryParams, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: auth,
          },
          body: blob,
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).then((resp) => {
          return resp.json().then((result) => {
            if (result.error) {
              // fetch does not throw error on normally responded server error
              throw new Error(result.message);
            } else {
              return result;
            }
          });
        });
      });
    });
  };

  /**
   * Load all asset resources from photos on iphone. Calling this again resets the initialization.
   * After this, all files available for fetching their binary data through blobsaver server: fetchChunkForIOSAsset
   * All chunks contains a maximum 1 megabyte data (depends on device?).
   */
  initIOSPhotosLibraryForFetching: () => Promise<Array<DeviceFile>> = () => {
    return this.getPort().then((port) => {
      return this.getAuthToken().then((auth) => {
        return fetch('http://localhost:' + port + '/load-photos', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: auth,
          },
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).then((resp) => {
          return resp.json().then((result) => {
            if (result.error) {
              // fetch does not throw error on normally responded server error
              //throw new Error(result.message);
              return null;
            } else {
              console.log(result);
              let serverRes: IOSLoadPhotosType[] = result.assets;
              return serverRes.map((e, fileIndexInIOSAssetList) => this._convertIOSFilePhotosRecordToDeviceFileRecord(e, fileIndexInIOSAssetList));
            }
          });
        });
      });
    });
  };

  /**
   *
   * @param assetIndex
   * @returns
   */
  fetchChunkForIOSAsset: (assetIndex: number) => Promise<{ chunk: ArrayBuffer; done: boolean }> = (
    assetIndex
  ) => {
    const queryParams = new URLSearchParams({ index: assetIndex + '' }).toString();
    //console.log("BACKUPDEBUG get this from server", assetIndex)
    return this.getPort().then((port) => {
      return this.getAuthToken().then((auth) => {
        return fetch('http://localhost:' + port + '/fetch-next-asset' + '?' + queryParams, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: auth,
          },
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }).then((resp) => {
          //console.log("BACKUPDEBUG got response",resp)
          return resp.arrayBuffer().then((chunk) => {
            //console.log("BACKUPDEBUG got chunk", chunk, !!resp.headers.get('X-Done'))
            return {
              chunk,
              done: !!resp.headers.get('X-Done'),
            };
          });
        });
      });
    });
  };

  _convertIOSFilePhotosRecordToDeviceFileRecord: (f: IOSLoadPhotosType, fileIndexInIOSAssetList: number) => DeviceFile = (f, fileIndexInIOSAssetList) => {
    var split = f.f_name.split('.');
    var ext = '';
    var name = '';
    if (split.length > 1) {
      ext = split.pop();
      name = split.join('.');
    } else {
      name = f.f_name;
    }

    return {
      name,
      ext,
      path: '',
      mimeType: '',
      size: f.size,
      mTime: f.m_date,
      mtFrac: f.mt_frac,
      fileIndexInIOSAssetList
    };
  };

  createFile = (name: string, blob: Blob, overwrite?: string, close?: boolean) => {
    return this.uploadFile({ name, close, overwrite }, blob);
  };

  appendFile = (id: any, blob: Blob, close?: boolean) => {
    return this.uploadFile({ id, close }, blob);
  };

  truncateFile = (id: any) => {
    return this.uploadFile({ id, truncate: true });
  };

  closeFile = (id: any) => {
    return this.uploadFile({ id, close: true });
  };

  getFileWriter = (
    fileName: string,
    overwrite?: string,
    convertPayload?: (blob: Blob) => Promise<Blob>
  ) => {
    return this.createFile(fileName, new Blob([]), overwrite).then((result) => {
      return {
        write: (blob: Blob) => {
          if (convertPayload) {
            return convertPayload(blob).then((payload) => {
              return this.appendFile(result.id, payload);
            });
          } else {
            return this.appendFile(result.id, blob);
          }
        },
        close: () => {
          return this.closeFile(result.id);
        },
        truncate: (size: number) => {
          return this.truncateFile(result.id);
        },
        getName: () => {
          return result.name;
        },
        getId: () => {
          return result.id;
        },
        getPath: () => {
          return result.path;
        },
      };
    });
  };

  /**
   * Android specific
   * @returns
   */
  public getFiles = (): Promise<Array<DeviceFile>> => {
    return Capacitor.Plugins.BlobSaver.getFilePathList().then((res: object) => {
      console.log('res object', res);
      if (res['fileAccessGranted'] === false) return null;
      return res['deviceFiles'] ?? [];
    });
  };
}
