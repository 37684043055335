<div id="file-manager-container">
  <ng-container *ngIf="!isBackupStatus(BackupStatus.DORMANT)">
    <div>
      <h1 translate>Phone Backup</h1>
      <h2 translate>Keep phone open until finished!</h2>
    </div>
    <div class="target-row">
      <mat-icon color="primary">computer</mat-icon>
      {{ lastBackupData?.nanoName }}
      <div *ngIf="isBackupStatus(BackupStatus.IN_PROGRESS) || isBackupStatus(BackupStatus.PAUSED)">({{ backupProgress }} / {{ backupFileList.length }})</div>
    </div>
    <ng-container *ngIf="isBackupStatus(BackupStatus.STARTED)">
      <div>
        <p translate>Click the button below to begin syncing your files to this Nano Drive: {{ lastBackupData?.nanoName }}</p>
        <p>
          (<span>{{ backupFileList.length }}</span
          ><span translate> files</span>)
        </p>
      </div>
      <div>
        <button color="primary" mat-raised-button (click)="beginBackup()"><span translate>Begin data backup</span></button>
      </div>
    </ng-container>
    <ng-container *ngIf="isBackupStatus(BackupStatus.IN_PROGRESS) || isBackupStatus(BackupStatus.PAUSED)">
      <mat-card appearance="outlined" *ngIf="isConnectionError">
        <mat-card-content>
          <mat-icon color="warn">warning</mat-icon>
          <span>Lost connection to the Nano Drive. Backup will resume after connection is regained.</span>
        </mat-card-content>
      </mat-card>
      <p *ngIf="backupCurrentFile">
        {{ backupCurrentFile?.name + '.' + backupCurrentFile?.ext }}
      </p>
      <p class="progress-row" [ngClass]="{ visible: showPartialProgress }">
        <mat-progress-bar [value]="partialProgress"></mat-progress-bar>
      </p>
      <p *ngIf="isBackupStatus(BackupStatus.IN_PROGRESS)">
        <button mat-raised-button (click)="pauseBackup()"><span translate>Pause</span></button>
      </p>
      <p *ngIf="isBackupStatus(BackupStatus.PAUSED)" class="backup-pause-buttons">
        <button color="primary" mat-raised-button (click)="resumeBackup()"><span translate>Resume</span></button>
        <button color="warn" mat-flat-button (click)="cancelBackup()"><span translate>Cancel</span></button>
      </p>
    </ng-container>
    <ng-container *ngIf="isBackupStatus(BackupStatus.DONE)">
      <ng-container *ngIf="backupErrorFileList.length > 0">
        <p translate>Backup finished, but some files could not be uploaded. You can find uploaded files in the <code>'Nano/Gallery/(your phone's name)'</code> folder.</p>
        <p translate>Files that could not be uploaded:</p>
        <mat-card class="error-card">
          <div class="error-file-container">
            <ol>
              <li *ngFor="let deviceFile of backupErrorFileList">{{ deviceFile.name }}.{{ deviceFile.ext }}</li>
            </ol>
          </div>
        </mat-card>
        <p>
          <button color="primary" mat-raised-button (click)="retryBackup()"><span transalte>Retry</span></button>
        </p>
      </ng-container>
      <ng-container *ngIf="backupErrorFileList.length === 0">
        <p translate>Backup finished to your Nano Drive! You can find your files in the 'Nano/Gallery/(your phone's name)' folder.</p>
        <p>
          <button color="primary" mat-raised-button (click)="closeBackup()"><span transalte>Close</span></button>
        </p>
      </ng-container>
    </ng-container>

    <br /><br />
  </ng-container>
  <ng-container *ngIf="fileList.length > 0 || collectionList.length > 0">
    <div id="title-row">
      <h1 id="title" translate>File Manager</h1>
      <button color="primary" mat-icon-button (click)="removeReady()" [matTooltip]="'Clear finished files' | translate">
        <mat-icon>remove_done</mat-icon>
      </button>
    </div>
    <mat-divider></mat-divider>
    <div id="file-manager-scroll">
      <!-- flex-direction: column reversed -->
      <div *ngFor="let file of fileList; let i = index">
        <app-download-manager-file [file]="file" [canResumeDownload]="canResumeDownload"></app-download-manager-file>
        <mat-divider *ngIf="i !== 0"></mat-divider>
      </div>
      <div *ngFor="let collection of collectionList; let i = index">
        <app-download-manager-collection [collection]="collection"></app-download-manager-collection>
        <mat-divider *ngIf="i !== 0 || fileList.length"></mat-divider>
      </div>
    </div>
  </ng-container>
</div>
