import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Subject, firstValueFrom, forkJoin, of } from 'rxjs';
import { defaultIfEmpty, delay } from 'rxjs/operators';
import { EnvironmentService } from 'src/environments/environment.service';
import {
  NANO_VERSION_FOR_BACKUP,
  NANO_VERSION_FOR_LOG_READ,
  nanoVersionCompare,
} from '../../drive-version';
import { AdminLog, AuthenticationObject, decompressUint8Array } from '../../nano/nano-requests';
import { NanoService, RequestSession } from '../../nano/nano.service';
import { ObjectSorter } from '../../object-sorter';
import { AuthService } from '../../server-services/auth.service';
import { PermissionService } from '../../server-services/permission.service';
import {
  DriveRecord,
  DriveRoomRecord,
  NanoSlotRecord,
  RemoteConfigRecord,
} from '../../server-services/query-records/nano-records';
import { RoomData, RoomRecord } from '../../server-services/query-records/room-records';
import { WorkspaceSubscriptionRoomEventRecord } from '../../server-services/query-records/workspace-records';
import { waitingNanosQuery } from '../../server-services/querys';
import { RoomService } from '../../server-services/room.service';
import { ServerError } from '../../server-services/server-errors';
import {
  SubscriptionServiceEvent,
  SubscriptionServiceEventType,
} from '../../server-services/subscription-event';
import { SubscriptionService } from '../../server-services/subscription.service';
import { DialogService } from '../../services/dialog.service';
import { ServerRestApiService } from '../../services/server-rest-api.service';
import { SnackBarService } from '../../services/snackbar.service';
import { VersionService } from '../../services/version.service';
import { WeightedSearch } from '../../weighted-search';
import { NewFolderDialogComponent } from '../new-folder-dialog/new-folder-dialog.component';
import { AdminAddBackupDialogComponent } from './admin-add-backup-dialog/admin-add-backup-dialog.component';
import {
  AdminPasswordDialogComponent,
  AdminPasswordMode,
} from './admin-password-dialog/admin-password-dialog.component';

export let ETALON_SOCKET_EXPIRATION_DATE = 946688461;

export enum NanoManagerDialogTabs {
  NANO_CLIENT_LIST = 0,
  NANO_DETAIL = 1,
  CREATE_DRIVE = 2,
  DRIVE_DETAIL = 3,
  ATTACH_ROOM = 4,
  DRIVE_PREVIEW = 5,
  NANO_CLIENT_SETTINGS = 6,
  NANO_LOGS = 7,
}

export type NanoLog = {
  log: string[];
  stream: string[];
  isReachedEnd: boolean;
  displayWindow: string[];
  displayPage: number;
  requestId: RequestSession | null;
};

@Component({
  selector: 'app-nano-manager-dialog',
  templateUrl: './nano-manager-dialog.component.html',
  styleUrls: ['./nano-manager-dialog.component.scss'],
})
export class NanoManagerDialogComponent implements OnInit, OnDestroy {
  public loading: boolean = true;
  public interactable: boolean = true;
  public activeTab: number = 0;
  public nanoFilterControl = new FormControl<string>('', []);
  public filteredNanoSlots: NanoSlotRecord[] = [];
  public nanoSlots: NanoSlotRecord[] = [];
  public selectedNanoSlot: NanoSlotRecord;
  public selectedNanoDrive: DriveRecord;
  public currentPath: string[] = [];
  public folders: Array<{ name: string; unresolved: boolean; free: number; total: number }> = [];
  public availableRooms: DriveRoomRecord[] = [];
  public selectedRoomId: string = null;
  public waitingNanos: number = 3;
  public tooltipDelay: number = 1000;
  public websiteDownloadPage: string = this.environmentService.url_landing + '/download';
  public isRefreshRequired: boolean = false;

  public selectedLogFile = '';
  public LOG_ACCESS = 'access';
  public LOG_SERVICE = 'service';

  public backups: { path: string; target_room_id: string }[] = [];

  public nanoClientForm: FormGroup = this.fb.group({
    nanoClientName: ['', [Validators.required, Validators.maxLength(200)]],
    denyAnonymous: [true, []],
    explicitPeerTrust: [false, []],
  });

  // private adminPasswords: { [key: number]: AuthenticationObject } = {};

  constructor(
    private selfDialogRef: MatDialogRef<NanoManagerDialogComponent>,
    private nanoService: NanoService,
    private versionService: VersionService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private snackbarService: SnackBarService,
    private roomService: RoomService,
    private permissionService: PermissionService,
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
    private serverRestApiService: ServerRestApiService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private environmentService: EnvironmentService,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(MAT_DIALOG_DATA) public data?: { roomId: string }
  ) {}

  ngOnInit(): void {
    this.bindEvents();
    this.initData();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromEvents();
    // this.nanoService.clearAuthObjects(); // allow token timeouts to handle token resets
    this.stopLogStream();
  }

  public refreshNanoSlots(): void {
    this.loading = true;
    this.initData();
  }

  private bindEvents(): void {
    this.nanoFilterControl.valueChanges.subscribe(this.filterInput.bind(this));
    this.subscribeToEvents();
  }

  private async initData(): Promise<void> {
    if (this.data?.roomId) {
      this.selectedRoomId = this.data.roomId;
    }
    this.nanoSlots = await this.nanoService.getNanoSlots();

    this.setEtalonSlots();
    this.removeOldSlots();
    this.countWaitingNanos();

    var activeNanoSlots = this.nanoSlots.filter(
      (nanoSlot) => nanoSlot.lastActive && this.wasActiveWithinMinutes(5, nanoSlot)
    );

    let promises: Promise<any>[] = [];

    for (let nanoSlot of activeNanoSlots) {
      let getInfoPromise = new Promise((resolve, reject) => {
        this.nanoService.adminGetInfo(nanoSlot.id, resolve, reject);
      })
        .then((nanoSlotDetail: any) => {
          nanoSlot.detail = nanoSlotDetail;
        })
        .catch((err) => {
          console.error('Get Nano Info error for slot ' + nanoSlot.id, err);

          var unresponsiveNanoSlot = this.nanoSlots.find((nc) => nc.id == nanoSlot.id);
          unresponsiveNanoSlot.occupiedSlot = true;
          this.startUnresponsiveSlotResetTimer(nanoSlot);
        });

      promises.push(getInfoPromise);
    }

    forkJoin(promises)
      .pipe(defaultIfEmpty(null))
      .subscribe(() => {
        this.filterInput();
        this.loading = false;
      });
  }

  public filterInput(val: string = '') {
    if (val) {
      this.filteredNanoSlots = WeightedSearch.search(val, ['detail.name'], this.nanoSlots);
    } else {
      this.filteredNanoSlots = ObjectSorter.sort(this.nanoSlots, 'detail.name');
    }
  }

  private setEtalonSlots(): void {
    this.nanoSlots
      .filter((ns) => ns.expiration == ETALON_SOCKET_EXPIRATION_DATE)
      .forEach((nc) => (nc.isEtalonSlot = true));
  }

  private removeOldSlots(): void {
    this.nanoSlots = this.nanoSlots.filter(
      (nc) => new Date(nc.expiration * 1000) > new Date() || nc.isEtalonSlot
    );
  }

  private startUnresponsiveSlotResetTimer(nanoSlot: NanoSlotRecord): void {
    of(nanoSlot)
      .pipe(delay(this.getWhenSlotAvailableInSeconds(nanoSlot) * 1000))
      .subscribe((unresponsiveNanoSlot) => {
        unresponsiveNanoSlot.occupiedSlot = false;
        this.cd.markForCheck();
      });
  }

  private wasActiveWithinMinutes(minutes: number, nanoClient: NanoSlotRecord): boolean {
    var currentDate = new Date();
    var currentDateMinutesAgo = new Date(
      currentDate.setMinutes(currentDate.getMinutes() - minutes)
    );
    return new Date(nanoClient.lastActive * 1000) > currentDateMinutesAgo;
  }

  private getWhenSlotAvailableInSeconds(nanoClient: NanoSlotRecord): number {
    var dateWhenFree = new Date(nanoClient.lastActive * 1000 + 1000 * 300);
    var currentDate = new Date();
    var secondsUntilActive = Math.floor((dateWhenFree.getTime() - currentDate.getTime()) / 1000);

    return secondsUntilActive;
  }

  public getLastActiveDate(nanoClient: NanoSlotRecord): string {
    var availableInSeconds = this.getWhenSlotAvailableInSeconds(nanoClient);
    var translatedText = this.translate.instant(marker('Last active: '));
    var date = new Date(availableInSeconds * 1000).toISOString().substr(14, 5);
    var translatedSuffixText = this.translate.instant(marker(' (available in')) + ` ${date})`;
    return (
      translatedText +
      formatDate(nanoClient.lastActive * 1000, 'medium', this.locale) +
      translatedSuffixText
    );
  }

  public isNanoUpToDate(nanoClient: NanoSlotRecord): boolean {
    return !this.versionService.isValidVersion(nanoClient.detail.version);
  }

  public selectNanoSlot(nanoClient: NanoSlotRecord): void {
    this.loading = true;

    const repeatSubj$ = new Subject<void>();
    repeatSubj$.subscribe(() => {
      this.refreshSelectedNano(nanoClient)
        .then(() => {
          this.loading = false;
          this.sortNanoSlotRooms();
          this.activeTab = NanoManagerDialogTabs.NANO_DETAIL;
          repeatSubj$.complete();
        })
        .catch((err) => {
          console.error(`Error during remote config query for Nano Client: ${nanoClient.id}`, err);
          if (this.nanoService.isAuthError(err)) {
            this.nanoService.clearAuthObject(nanoClient.id);
            repeatSubj$.next(); //retry
          } else {
            this.loading = false;
            repeatSubj$.complete();
          }
        });
    });
    repeatSubj$.next(); //kickoff
  }

  private refreshSelectedNano(nanoClient: NanoSlotRecord): Promise<void> {
    return new Promise((resolve, reject) => {
      this.nanoService
        .getUserAuthObject(nanoClient)
        .then((authObject) => {
          this.nanoService.adminGetRemoteConfig(
            nanoClient.id,
            authObject,
            (remoteConfig) => {
              this.extendNanoWithRemoteConfig(nanoClient, remoteConfig)
                .then(() => {
                  console.log('nano', nanoClient.detail);
                  this.nanoService.setAuthObject(nanoClient.id, authObject);
                  this.isNanoLogFeatureAvailable =
                    nanoVersionCompare(
                      this.selectedNanoSlot.detail.version,
                      NANO_VERSION_FOR_LOG_READ
                    ) >= 0;
                  this.isNanoBackupFeatureAvailable =
                    nanoVersionCompare(
                      this.selectedNanoSlot.detail.version,
                      NANO_VERSION_FOR_BACKUP
                    ) >= 0;
                  resolve();
                })
                .catch(reject);
            },
            reject
          );
        })
        .catch(reject);
    });
  }

  public selectDrive(drive: DriveRecord): void {
    this.selectedNanoDrive = drive;
    this.activeTab = NanoManagerDialogTabs.DRIVE_DETAIL;
  }

  public getTopLevelFolderName(path: string): string {
    if (path.endsWith(':/') || path.endsWith(':\\')) return path;

    return path.split('/').pop().split('\\').pop();
  }

  public getRestOfPath(path: string): string {
    return path.substring(0, path.lastIndexOf(this.getTopLevelFolderName(path)));
  }

  public back(index = 0): void {
    this.activeTab = index;
  }

  public backToDrives(): void {
    this.currentPath = [];
    this.back(1);
  }

  public backToAttachRoom(): void {
    this.selectedRoomId = null;
    this.openAttachRoom();
    this.back(4);
  }

  public isRootFolder(name: string): boolean {
    return name.endsWith(':/') || name.endsWith('/');
  }

  public getPath(name: string = ''): string {
    return this.currentPath.join('') + name;
  }

  private extendNanoWithRemoteConfig(
    nanoClient: NanoSlotRecord,
    remoteConfig: RemoteConfigRecord
  ): Promise<void> {
    nanoClient.detail.remoteConfig = remoteConfig;
    this.selectedNanoSlot = nanoClient;

    let promises: Promise<void>[] = [];
    for (const drive of nanoClient.detail.remoteConfig.drives) {
      drive.roomDetails = new Array<DriveRoomRecord>();
      if ('rooms' in drive) {
        for (const roomId of drive.rooms) {
          let promise = this.roomService
            .getRoom(roomId)
            .then((roomWithData) => {
              drive.roomDetails.push({
                id: roomId,
                name: (<RoomData>roomWithData.data)?.name,
                isValid: true,
                avatar: (<RoomData>roomWithData.data)?.avatar,
              });
            })
            .catch((err) => {
              drive.roomDetails.push({
                id: roomId,
                name: null,
                isValid: false,
                avatar: null,
              });
            });

          promises.push(promise);
        }
      }

      drive.backupDetails = new Array<DriveRoomRecord>();
      if ('backups' in drive) {
        for (const backupId of drive.backups) {
          let promise = this.roomService
            .getRoom(backupId)
            .then((roomWithData) => {
              drive.backupDetails.push({
                id: backupId,
                name: (<RoomData>roomWithData.data)?.name,
                isValid: true,
                avatar: (<RoomData>roomWithData.data)?.avatar,
              });
            })
            .catch((err) => {
              drive.backupDetails.push({
                id: backupId,
                name: backupId,
                isValid: false,
                avatar: null,
              });
            });

          promises.push(promise);
        }
      }
    }
    return forkJoin(promises).pipe(defaultIfEmpty(null)).toPromise();
  }

  private sortNanoSlotRooms(): void {
    for (const drive of this.selectedNanoSlot.detail.remoteConfig.drives) {
      ObjectSorter.sort(drive.roomDetails, 'name');
      ObjectSorter.sort(drive.backupDetails, 'name');
    }
  }

  public newFolderDialog() {
    var dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: { name: 'New Nano Drive' },
    });

    dialogRef.afterClosed().subscribe((folderName) => {
      if (!folderName) return;

      const repeatSubj$: Subject<void> = new Subject<void>();
      repeatSubj$.subscribe(() => {
        this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
          this.nanoService.adminMakeDir(
            this.selectedNanoSlot.id,
            this.getPath(),
            folderName,
            authObj,
            (result) => {
              this.snackbarService.showSnackbar(marker('Folder created successfully!'));
              this.openFolder(result.createdName);
              repeatSubj$.complete();
            },
            (err) => {
              if (this.nanoService.isAuthError(err)) {
                this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
                repeatSubj$.next(); //retry
              } else {
                this.snackbarService.showSnackbar(marker('Error during folder creation.'));
                repeatSubj$.complete();
              }
            }
          );
        });
      });
      repeatSubj$.next(); //kickoff
    });
  }

  public openNewDriveTab(): void {
    this.loading = true;
    this.listFolders().then(() => {
      this.loading = false;
      this.activeTab = NanoManagerDialogTabs.CREATE_DRIVE;
    });
  }

  public openFolder(folderName: string): void {
    if (!this.interactable) return;

    this.interactable = false;
    this.listFolders(this.getPath(folderName))
      .then(() => {
        if (!folderName.endsWith('/')) folderName += '/';
        this.currentPath.push(folderName);
        this.interactable = true;
      })
      .catch(() => {
        this.interactable = true;
      });
  }

  public folderBack(): void {
    if (!this.interactable) return;

    this.interactable = false;
    let oneLessPath = this.currentPath.slice(0, -1);
    this.listFolders(oneLessPath.join('')).then(() => {
      this.currentPath = oneLessPath;
      this.interactable = true;
    });
  }

  private listFolders(path: string = ''): Promise<void> {
    return new Promise((resolve, reject) => {
      const repeatSubj$: Subject<void> = new Subject<void>();
      repeatSubj$.subscribe(() => {
        this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObject) => {
          this.nanoService.adminGetListing(
            this.selectedNanoSlot.id,
            path,
            authObject,
            (res) => {
              //console.log('Admin list folder response', res);
              this.folders = res.map((r) => {
                return {
                  name: r.name,
                  unresolved: r.unresolved ? r.unresolved : false,
                  free: r.free ? r.free : undefined,
                  total: r.total,
                };
              });

              repeatSubj$.complete();
              resolve();
            },
            (err) => {
              if (this.nanoService.isAuthError(err)) {
                this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
                repeatSubj$.next(); //retry
              } else {
                this.dialogService.openAlertDialog(
                  marker('Unreachable folder'),
                  marker(
                    'Sorry, but Nano is not able to access this folder. Please choose an other.'
                  )
                );

                repeatSubj$.complete();
                reject({ message: 'Unreachable folder.', error: err });
              }
            }
          );
        });
      });
      repeatSubj$.next(); //kickoff
    });
  }

  public createDrive(): void {
    const repeatSubj$: Subject<void> = new Subject<void>();
    this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
      console.log(this.selectedNanoSlot, this.currentPath);
      this.nanoService.adminCreateDrive(
        this.selectedNanoSlot.id,
        this.currentPath.join(''),
        authObj,
        () => {
          this.snackbarService.showSnackbar(marker('Drive created successfully!'));
          this.currentPath = [];
          this.selectNanoSlot(this.selectedNanoSlot);
          repeatSubj$.complete();
        },
        (err) => {
          console.error('Error during drive creation.', err);

          if (this.nanoService.isAuthError(err)) {
            this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
            repeatSubj$.next(); //retry
          } else if (err?.error === ServerError.NANO_REQUEST_ERROR_HANDLER_DENIED) {
            this.dialogService
              .openAlertDialog(
                marker('Invalid folder'),
                marker(
                  "The Nano Client's security settings disallow the creation of the drive from the selected folder. Please choose a different folder."
                )
              )
              .subscribe(() => this.backToDrives());
            repeatSubj$.complete();
          } else {
            repeatSubj$.complete();
          }
        }
      );
    });

    repeatSubj$.next(); //kickoff
  }

  public detachRoom(room: DriveRoomRecord): void {
    //ask for detach confirmation and request nano password
    console.log('detach room', room.id);
    this.dialogService
      .openConfirmDialog(
        marker('Detach Room'),
        marker('Are you sure you want to detach this room?')
      )
      .subscribe((confirm) => {
        if (confirm) {
          this.loading = true;

          const repeatSubj$ = new Subject<void>();
          repeatSubj$.subscribe(() => {
            this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
              this.nanoService.adminDetachDrive(
                this.selectedNanoSlot.id,
                this.selectedNanoDrive.path,
                room.id,
                authObj,
                () => {
                  this.snackbarService.showSnackbar(
                    marker('Room removed from drive successfully!')
                  );
                  repeatSubj$.complete();
                },
                (err) => {
                  console.error('Detach drive error', err);
                  if (this.nanoService.isAuthError(err)) {
                    this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
                    repeatSubj$.next(); //retry
                  } else {
                    this.loading = false;
                  }
                }
              );
            });
          });
          repeatSubj$.next(); //kickoff
        }
      });
  }

  private subscribeToEvents(): void {
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomEventModify
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.NANO_WAITING_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.countWaitingNanos
    );
    this.subscriptionService.subscribe(
      SubscriptionServiceEvent.NANO_WAITING_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.countWaitingNanos
    );
  }

  private unsubscribeFromEvents(): void {
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.ROOM_EVENT,
      SubscriptionServiceEventType.MODIFY,
      this.handleRoomEventModify
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.NANO_WAITING_EVENT,
      SubscriptionServiceEventType.CREATE,
      this.countWaitingNanos
    );
    this.subscriptionService.unsubscribe(
      SubscriptionServiceEvent.NANO_WAITING_EVENT,
      SubscriptionServiceEventType.DELETE,
      this.countWaitingNanos
    );
  }

  private handleRoomEventModify = (event: WorkspaceSubscriptionRoomEventRecord) => {
    //we want to refresh the "selected drive" but only when a "selected drive" exists
    if (this.selectedNanoDrive && (event.nanoSessionsRemoved || this.isRefreshRequired)) {
      //refresh nano config rooms
      this.isRefreshRequired = false;
      this.refreshSelectedNano(this.selectedNanoSlot).then(() => {
        this.selectDrive(
          this.selectedNanoSlot.detail.remoteConfig.drives.find(
            (d) => d.path === this.selectedNanoDrive.path
          )
        );
        this.sortNanoSlotRooms();
        this.loading = false;
      });
    }
  };

  private countWaitingNanos = () => {
    this.serverRestApiService
      .query({
        query: waitingNanosQuery,
      })
      .then((response: { [key: string]: number }) => {
        this.waitingNanos = Object.keys(response).length;
      });
  };

  public openAttachRoom = () => {
    this.availableRooms = [];
    this.loading = true;

    if (this.selectedRoomId) {
      // this is the workflow when we are opening nano manager from a room's empty drive tab
      // selected room id already exist, load the room and let the user change later
      this.roomService.getRoom(this.selectedRoomId).then((roomWithData) => {
        if (!roomWithData.data.decryptionError) {
          var roomRecord: DriveRoomRecord = {
            id: roomWithData.id,
            name: roomWithData.data?.name,
            isValid: !this.hasActiveDriveSession(roomWithData.nanoSessions),
            avatar: roomWithData.data?.avatar,
          };
          this.selectRoom(roomRecord);
          this.loading = false;
        }
      });
    } else {
      // selected room id does not exists yet, load all the rooms
      this.roomService.getMyRooms().then((myRooms: RoomRecord[]) => {
        if (myRooms.length == 0) {
          myRooms = [];
        }

        let promises: Promise<any>[] = [];
        for (const room of myRooms) {
          const promise = this.roomService.getRoom(room.id).then((roomWithData) => {
            if (!roomWithData.data.decryptionError) {
              var roomRecord: DriveRoomRecord = {
                id: roomWithData.id,
                name: roomWithData.data?.name,
                isValid: !this.hasActiveDriveSession(roomWithData.nanoSessions),
                avatar: roomWithData.data?.avatar,
              };
              this.availableRooms.push(roomRecord);
            }
          });
          promises.push(promise);
        }

        forkJoin(promises)
          .pipe(defaultIfEmpty(null))
          .subscribe(() => {
            this.activeTab = NanoManagerDialogTabs.ATTACH_ROOM;
            this.loading = false;
          });
      });
    }
  };

  private hasActiveDriveSession(nanoSessions: Object): boolean {
    return Object.keys(nanoSessions).length > 0;
  }

  public selectRoom(room: DriveRoomRecord): void {
    if (room.isValid) {
      this.selectedRoomId = room.id;
      this.activeTab = NanoManagerDialogTabs.DRIVE_PREVIEW;
    }
  }

  /**
   * Search for this room is the backup list of any other drives on this Nano
   * remove these backups
   * @param roomId
   */
  private removeAnyBackups(roomId) {
    for (const drive of this.selectedNanoSlot.detail.remoteConfig.drives) {
      if (drive.backups && drive.backups.includes(roomId)) {
        console.log('remove backup from this drive', roomId, drive.path);
        this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
          this.nanoService.adminDeleteBackup(
            this.selectedNanoSlot.id,
            roomId,
            drive.path,
            authObj,
            () => {},
            (err) => {
              this.snackbarService.showSnackbar(marker('Some of the backups failed to remove.'));
              console.error('backup error', err);
            }
          );
        });
      }
    }
  }

  private isRoomBackup(roomId: string): boolean {
    for (const drive of this.selectedNanoSlot.detail.remoteConfig.drives) {
      if (drive.backups && drive.backups.includes(roomId)) {
        return true;
      }
    }
    return false;
  }

  public attachRoom(): void {
    this.loading = true;

    this.permissionService
      .getRoomBlockChain(this.selectedRoomId, this.authService.getSelfAccountKeyring())
      .then((chain) => {
        const repeatSubj$ = new Subject<void>();
        repeatSubj$.subscribe(async () => {
          console.log('repeat subj');

          // check if this room is already a backup room on this Nano
          if (this.isRoomBackup(this.selectedRoomId)) {
            // dialog to confirm if user wants to attach this room as a regular room and remove any backup
            let confirm = await firstValueFrom(
              this.dialogService.openConfirmDialog(
                marker('Selected room is already a backup'),
                marker(
                  'This room is already attached as a backup on this drive. Do you want to attach it as a regular room? This will remove any backup status from this room.'
                )
              )
            );
            if (!confirm) {
              this.loading = false;
              repeatSubj$.complete();
              return;
            }

            // remove any backup status from this room
            await this.removeAnyBackups(this.selectedRoomId);
            this.loading = false;
          }

          // attach
          this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
            this.nanoService.adminAttachDrive(
              this.selectedNanoSlot.id,
              this.selectedNanoDrive.path,
              this.selectedRoomId,
              chain.head_names,
              authObj,
              () => {
                this.snackbarService.showSnackbar(marker('Room added to drive successfully!'));
                this.refreshSelectedNano(this.selectedNanoSlot).then(() => {
                  this.selectDrive(
                    this.selectedNanoSlot.detail.remoteConfig.drives.find(
                      (d) => d.path === this.selectedNanoDrive.path
                    )
                  );
                  this.sortNanoSlotRooms();
                  this.loading = false;
                  this.selectedRoomId = null;
                  repeatSubj$.complete();
                });
              },
              (err) => {
                console.error('Room failed to attach', err);
                if (this.nanoService.isAuthError(err)) {
                  this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
                  repeatSubj$.next(); //retry
                } else {
                  repeatSubj$.complete();
                }
              }
            );
          });
        });
        repeatSubj$.next(); //kickoff
      });
  }

  public deleteDrive(): void {
    this.dialogService
      .openConfirmDialog(
        marker('Delete Drive'),
        marker(
          'Are you sure you want to delete this Drive? Your local files will not be deleted, only the Nano Drive.'
        )
      )
      .subscribe((confirm) => {
        if (!confirm) return;

        this.loading = true;

        const repeatSubj$ = new Subject<void>();
        repeatSubj$.subscribe(() => {
          this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
            this.nanoService.adminDeleteDrive(
              this.selectedNanoSlot.id,
              this.selectedNanoDrive.path,
              authObj,
              () => {
                this.snackbarService.showSnackbar(marker('Drive successfully deleted.'));
                this.currentPath = [];
                this.selectedNanoDrive = null;
                this.selectNanoSlot(this.selectedNanoSlot);
                repeatSubj$.complete();
              },
              (err) => {
                console.error('Delete drive error', err);
                if (this.nanoService.isAuthError(err)) {
                  this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
                  repeatSubj$.next(); //retry
                } else {
                  repeatSubj$.complete();
                }
              }
            );
          });
        });
        repeatSubj$.next(); //kickoff
      });
  }

  public openClientSettings(): void {
    this.refreshSelectedNano(this.selectedNanoSlot).then(() => {
      this.nanoClientForm = this.fb.group({
        nanoClientName: [
          this.selectedNanoSlot.detail.name,
          [Validators.required, Validators.maxLength(40)],
        ],
        denyAnonymous: [this.selectedNanoSlot.detail.remoteConfig.deny_anonymous, []],
        explicitPeerTrust: [
          this.selectedNanoSlot.detail.remoteConfig.require_explicit_peer_trust,
          [],
        ],
      });

      this.activeTab = NanoManagerDialogTabs.NANO_CLIENT_SETTINGS;
    });
  }
  public isNanoLogFeatureAvailable: boolean = false;
  public isNanoBackupFeatureAvailable: boolean = false;

  public goBackFromNanoLog() {
    this.stopLogStream();
    this.openClientSettings();
  }

  public editNanoPassword(): void {
    const repeatSubj$ = new Subject<void>();
    repeatSubj$.subscribe(() => {
      const dialogRef = this.dialog.open(AdminPasswordDialogComponent, {
        data: { adminPasswordMode: AdminPasswordMode.CHANGE },
      });

      dialogRef
        .afterClosed()
        .subscribe((promptResponse: { password: string; passwordAgain: string }) => {
          if (promptResponse) {
            this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
              this.nanoService.adminChangePasswordRequest(
                this.selectedNanoSlot.id,
                promptResponse.password,
                authObj,
                () => {
                  this.snackbarService.showSnackbar(marker('Change admin password successful!'));
                  repeatSubj$.complete();
                },
                (err) => {
                  console.log('error', err);
                  if (this.nanoService.isAuthError(err)) {
                    repeatSubj$.next(); //retry
                  } else {
                    repeatSubj$.complete();
                  }
                }
              );
            });
          }
        });
    });
    repeatSubj$.next(); //kickoff
  }

  // NANO LOGS -------------------------------------------------------------------
  public openNanoLogs() {
    this.activeTab = NanoManagerDialogTabs.NANO_LOGS;
  }

  public selectLogFileToRead(logFile: string) {
    this.selectedLogFile = logFile;
    this.startLogStream();
  }

  public logs: { [file: string]: NanoLog } = {};

  public LOG_LINE_PER_PAGE = 200;

  public stopLogStream() {
    this.selectedLogFile = undefined;
    for (let key in this.logs) {
      this.logs[key].requestId.pause();
      delete this.logs[key];
    }
  }

  public showLogIsLoading: boolean = false;
  public showLogPage(input: any) {
    let page = parseInt(input);
    if (!Number.isInteger(page)) return;

    let logObj = this.logs[this.selectedLogFile];

    if (page == -1) {
      page = Math.floor(logObj.log.length / this.LOG_LINE_PER_PAGE);
    }

    if (this.showLogIsLoading) return;

    this.showLogIsLoading = true;

    logObj.displayPage = page;
    if (logObj.displayPage < 0) {
      logObj.displayPage = 0;
    }
    if (
      logObj.isReachedEnd &&
      logObj.displayPage > Math.floor(logObj.log.length / this.LOG_LINE_PER_PAGE)
    ) {
      logObj.displayPage = Math.floor(logObj.log.length / this.LOG_LINE_PER_PAGE);
    }

    let currentLogs = logObj.log.slice(
      logObj.displayPage * this.LOG_LINE_PER_PAGE,
      (logObj.displayPage + 1) * this.LOG_LINE_PER_PAGE
    );

    if (currentLogs.length < this.LOG_LINE_PER_PAGE && !logObj.isReachedEnd) {
      let maxBeforeLoad = Math.floor(logObj.log.length / this.LOG_LINE_PER_PAGE);
      this.loadMoreLog(() => {
        this.showLogIsLoading = false;

        if (logObj.displayPage > maxBeforeLoad) {
          logObj.displayPage = maxBeforeLoad;
        }

        this.showLogPage(logObj.displayPage);
      });
    } else {
      logObj.displayWindow = currentLogs;
      this.showLogIsLoading = false;
    }
  }

  public startLogStream() {
    if (this.logs[this.selectedLogFile]) {
      this.mergeNewMessages();
      this.showLogPage(0);
      return;
    }

    this.logs[this.selectedLogFile] = {
      displayPage: 0,
      displayWindow: [],
      isReachedEnd: false,
      log: [],
      requestId: null,
      stream: [],
    };

    let logObj = this.logs[this.selectedLogFile];
    let logFirstShow = true;

    let logFile = this.selectedLogFile;

    this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
      logObj.requestId = this.nanoService.adminLogStream(
        this.selectedNanoSlot.id,
        this.selectedLogFile,
        true,
        authObj,
        (res) => {
          console.log('log stream done', res);
          //this.logs[this.selectedLogFile].push(res[0].log);
        },
        (res) => {
          console.log('log stream err', res);
        },
        (curr, max, session, msg: any) => {
          let part: AdminLog = msg?.response?.fwd;
          if (!part || part.logs?.length == 0) {
            return;
          }

          let push = (log) => {
            //console.log('AAA stream prog', log);
            let lines = log.split('\n').reverse();
            logObj.stream = lines.concat(logObj.stream[this.selectedLogFile]);
            if (logFirstShow) {
              logFirstShow = false;
              this.mergeNewMessages();
              this.showLogPage(0);
            }
          };

          if (part.compression) {
            return decompressUint8Array(part.logs, part.compression).then((log) => {
              return log.arrayBuffer().then((buff) => {
                push(new TextDecoder('utf-8').decode(new Uint8Array(buff)));
                return;
              });
            });
          } else {
            push(new TextDecoder('utf-8').decode(part.logs));
            return;
          }
        }
      );
    });
  }

  public loadMoreLog(cb) {
    let logObj = this.logs[this.selectedLogFile];
    this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
      this.nanoService.adminReadLogBackward(
        this.selectedNanoSlot.id,
        this.selectedLogFile,
        authObj,
        (res) => {
          let lines = res[0].log.split('\r\n').reverse();
          //console.log('loadmore done', lines);
          logObj.log = logObj.log.concat(lines);
          if (res[0].end) {
            logObj.isReachedEnd = true;
          }
          cb && cb();
        },
        (res) => {
          console.log('log read err', res);
        },
        (res) => {
          console.log('log read progress');
        }
      );
    });
  }

  public mergeNewMessages() {
    let logObj = this.logs[this.selectedLogFile];
    logObj.log = logObj.stream.concat(logObj.log);
    logObj.stream = [];
  }
  // ---------------------------------------------------------------------------------

  public discardEditSettings(): void {
    this.nanoClientForm.reset();
    this.activeTab = NanoManagerDialogTabs.NANO_DETAIL;
  }

  public editSettings(): void {
    this.loading = true;

    const repeatSubj$ = new Subject<void>();
    repeatSubj$.subscribe(() => {
      this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
        Promise.all([
          this.editNamePromise(authObj),
          this.editAnonymPromise(authObj),
          this.editPeerTrustPromise(authObj),
        ])
          .then(() => {
            this.snackbarService.showSnackbar(marker('Nano Client settings saved!'));
            this.selectedNanoSlot.detail.name = this.nanoClientForm.get('nanoClientName').value;
            this.selectNanoSlot(this.selectedNanoSlot);
            this.filterInput();
            this.loading = false;
            repeatSubj$.complete();
          })
          .catch((err) => {
            console.error('Edit nano error', err);
            if (this.nanoService.isAuthError(err)) {
              this.nanoService.clearAuthObject(this.selectedNanoSlot.id);
              repeatSubj$.next(); //repeat
            } else {
              this.dialogService.openAlertDialog(
                marker('Error'),
                marker('Error during the operation'),
                err
              );
              this.loading = false;
              repeatSubj$.complete();
            }
          });
      });
    });
    repeatSubj$.next(); //kickoff
  }

  private editNamePromise(authObj: AuthenticationObject): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.nanoClientForm.get('nanoClientName').dirty) {
        this.nanoService.adminEditNameRequest(
          this.selectedNanoSlot.id,
          this.nanoClientForm.get('nanoClientName').value,
          authObj,
          resolve,
          reject
        );
      } else {
        resolve();
      }
    });
  }

  private editAnonymPromise(authObj: AuthenticationObject): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.nanoClientForm.get('denyAnonymous').dirty) {
        this.nanoService.adminEditDenyAnonymous(
          this.selectedNanoSlot.id,
          this.nanoClientForm.get('denyAnonymous').value,
          authObj,
          resolve,
          reject
        );
      } else {
        resolve();
      }
    });
  }

  private editPeerTrustPromise(authObj: AuthenticationObject): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.nanoClientForm.get('explicitPeerTrust').dirty) {
        this.nanoService.adminEditRequireExplicitPeerTrust(
          this.selectedNanoSlot.id,
          this.nanoClientForm.get('explicitPeerTrust').value,
          authObj,
          resolve,
          reject
        );
      } else {
        resolve();
      }
    });
  }

  public closeDialog = () => {
    const dialogClose = this.dialogService.openConfirmDialog(
      marker('Are you sure you want to close the Nano manager?'),
      '',
      marker('Ok'),
      marker('Back')
    );
    dialogClose.subscribe((confirmed) => {
      if (confirmed) {
        this.selfDialogRef.close();
      } else {
        // nothing?
      }
    });
  };

  public openNewBackupDialog() {
    this.dialog
      .open(AdminAddBackupDialogComponent)
      .afterClosed()
      .subscribe((resp: { room_id: string }) => {
        console.log('backup dialog response', resp);
        if (this.isRoomBackup(resp.room_id)) {
          this.snackbarService.showSnackbar(marker('Room is already in the backups on this Nano.'));
          return;
        }

        if (!resp) return;
        // check if backup room is already hosted by the selected nano
        if (
          this.selectedNanoSlot.detail.remoteConfig.drives.some((drive) =>
            drive.rooms.includes(resp.room_id)
          )
        ) {
          this.snackbarService.showSnackbar(
            marker('Room is already hosted by this Nano! Backuping itself is not allowed.')
          );
          return;
        }

        this.loading = true;
        this.isRefreshRequired = true;
        this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
          this.nanoService.adminAddBackup(
            this.selectedNanoSlot.id,
            resp.room_id,
            this.selectedNanoDrive.path,
            authObj,
            () => {
              this.snackbarService.showSnackbar(marker('Backup added successfully!'));
              false;
            },
            (err) => {
              this.snackbarService.showSnackbar(marker('Backup error!'));
              console.error('backup error', err);
              this.loading = false;
            }
          );
        });
      });
  }

  public removeBackup(roomId: string) {
    // Note: when there is no room connected to the drive, the remove backup will
    // not trigger roomevent modify event, so we need to manually refresh the nano
    // if we need this feature
    this.dialogService
      .openConfirmDialog(
        marker('Remove Backup'),
        marker('Are you sure you want to remove this backup?')
      )
      .subscribe((confirm) => {
        if (!confirm) return;
        this.loading = true;
        this.isRefreshRequired = true;
        this.nanoService.getUserAuthObject(this.selectedNanoSlot).then((authObj) => {
          this.nanoService.adminDeleteBackup(
            this.selectedNanoSlot.id,
            roomId,
            this.selectedNanoDrive.path,
            authObj,
            () => {
              this.snackbarService.showSnackbar(marker('Backup removed successfully!'));
              this.loading = false;
            },
            (err) => {
              this.snackbarService.showSnackbar(marker('Backup error!'));
              console.error('backup error', err);
              this.loading = false;
            }
          );
        });
      });
  }
}
