import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SheetAction } from 'src/app/shared/consts/enums';
import { RouterHandler } from 'src/app/shared/services/router-handler.service';
import { DriveFile } from '../drive-file';

@Component({
  selector: 'app-bottom-sheet-menu',
  templateUrl: './bottom-sheet-menu.component.html',
  styleUrls: ['./bottom-sheet-menu.component.scss'],
})
export class BottomSheetMenuComponent implements OnInit, OnDestroy {
  public SheetAction = SheetAction;
  public allowAnonym: boolean = false;
  public interactingFiles: DriveFile[] = [];
  public isOnRoot: boolean = false;

  public hasDirectoryPicker = window.hasOwnProperty('showDirectoryPicker');

  constructor(
    private _bottomSheet: MatBottomSheetRef<BottomSheetMenuComponent>,
    private routerHandler: RouterHandler,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    protected data: {
      selectedFiles: DriveFile[];
      lastInteracted: DriveFile;
      allowAnonym: boolean;
      canBackup: boolean;
    }
  ) {
    this.allowAnonym = data.allowAnonym;

    if (data.lastInteracted) this.interactingFiles.push(data.lastInteracted);
    if (data.selectedFiles.length > 1) this.interactingFiles = data.selectedFiles;

    this.routerHandler.subscribe('room/?/drive', this.onRouterChange);
    if (this.routerHandler.isOnPage('room/?/drive')) {
      this.onRouterChange(this.routerHandler.getRoute());
    }
  }

  public onRouterChange = (res) => {
    if (res.fragment?.path) {
      const paths = res.fragment?.path.split('||');
      if (paths.length > 0) {
        this.isOnRoot = false;
      } else {
        this.isOnRoot = true;
      }
    } else {
      this.isOnRoot = true;
    }
  };

  ngOnDestroy() {
    this.routerHandler.unsubscribe('room/?/drive', this.onRouterChange);
  }

  ngOnInit(): void {}

  sheetActionClick(action: SheetAction): void {
    this._bottomSheet.dismiss(action);
  }
}
